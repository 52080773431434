.btn--suite {
    font-family: "Gotham" !important;
    font-size: 0.895em;
    font-weight: 700;
    padding: 0 50px 0 0;
    margin-top: 40px;
    text-transform: uppercase;
    border: 0;
    color: $btncolor;
    position: relative;
    margin-left: 0;
    -webkit-transition: all 0.1s ease-out;
    transition: all 0.1s ease-out;

    @media (max-width: 767px) {
      width: 175px;
      display: block;
      margin-left: calc(50% - 80px);
    }

    &:after {
      content: '';
      display: block;
      height: 3px;
      width: 31px;
      background: #71a79e;
      position: absolute;
      top: 8px;
      right: 0;
    }
}

.btn--suite:hover {
  color: $btncolor;

  @media (min-width: 768px) {
    padding: 0 60px 0 0;
  }
}

.btn--suite:focus, .btn--suite:active {
  box-shadow: none;
}
