/*DESCRIPTION-----------------------------------------------------------------*/

.description {
  background: $bgcolor2;
  position: relative;

  @media (max-width: 767px) {
    padding-top: 200px;
    overflow: hidden;
  }

  &:before {
    content: " ";
    position: absolute;
    top: -170px;
    left: 0;
    right: 0;
    width: 100%;
    height: 170px;
    background: $bgcolor2;
    z-index: -1;

    @media (max-width: 767px) {
      top: 0;
      z-index: 2;
      background: $bgcolor1;
    }
  }

  .picture {
    z-index: 2;

    @media (max-width: 767px) {
      position: absolute;
      max-width: 280px;
      top: -120px;
      right: -80px;
    }

    img {
      max-width: 100%;
      margin-top: -60px;

      @media (min-width: 768px) {
        margin-top: -30px;
      }
      @media (min-width: 992px) {
        margin-top: -60px;
      }
    }
  }

  .content {
    padding-top: 145px;
    padding-bottom: 200px;

    @media (max-width: 767px) {
      padding-bottom: 80px;
    }
  }
}

/*CABINET-----------------------------------------------------------------*/

.cabinet {
  background: $bgcolor2;
  position: relative;
  overflow: hidden;
  z-index: 0;

  @media (max-width: 767px) {
    background: $bgcolor1;
  }

  &:before{
      @media (max-width: 767px) {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 250px;
        background: $bgcolor2;
        z-index: 0;
      }
    }

  .container {
    background: $bgcolor1;
    padding-top: 80px;
    z-index: -1;

    @media (max-width: 767px) {
      padding-top: 500px;
      padding-bottom: 80px;
      background: transparent;
    }
  }

  .padding-no {
    padding-left: 0;
    z-index: 2;
  }

  .picture {
    max-width: 100%;
    height: 520px;

    img {
      object-fit: cover;
      width: calc(100% + 15px);
      height: 100%;
    }

    @media (max-width: 767px) {
      height: 500px;
      position: absolute;
      top: -500px;
      left: 20vw;
    }
  }

  .content {
    padding-top: 100px;

    @media (min-width: 767px) {
      padding-top: 50px;
    }

    @media (min-width: 992px) {
      padding-top: 100px;
    }
  }
}

/*SPECIALITES-----------------------------------------------------------------*/

.specialites {
  position: relative;

  &:before {
    @media (min-width: 768px) {
      content: " ";
      position: absolute;
      top: -80px;
      left: 0;
      right: 0;
      width: 100%;
      height: 80px;
      background: #fff;
      z-index: 1;
    }
  }


  .container {
    padding-top: 140px;
    padding-bottom: 130px;

    @media (max-width: 767px) {
      padding-top: 100px;
      padding-bottom: 100px;
    }
  }

  .padding-no {
    padding-left: 0;
  }

  .picture {
    max-width: 100%;
    height: 700px;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .content {
    .margin {
      @media (max-width: 767px) {
        margin-top: 80px;
      }
    }

    @media (max-width: 767px) {
      max-width: 420px;
      margin: 0 auto;
    }
  }
}

.block-specialites {
  .title-specialites {
    margin-bottom: 30px;
  }

  .list__el {
    padding-bottom: 15px;
    position: relative;

    @media (max-width: 991px) {
      padding-left: 20px;
    }
  }
  .list__el:last-child {
    padding-bottom: 0;
  }
  .list__el:before {
    content: '';
    display: block;
    height: 3px;
    width: 31px;
    background: #71a79e;
    position: absolute;
    top: 9px;
    left: -55px;

    @media (max-width: 991px) {
      width: 5px;
      height: 5px;
      border-radius: 5px;
      top: 8px;
      left: 0;
    }
  }
}

/*SPORT---------------------------------------------------------------------*/

.title-sport {
  margin-bottom: 35px;
}

.sport {
  background-color: $bgcolor2;

  .container {
    padding-top: 140px;

    @media (max-width: 767px) {
      padding-top: 100px;
    }
  }

  .content {
    padding-bottom: 260px;

    @media (max-width: 767px) {
      padding-bottom: 120px;
    }

    @media (min-width: 768px) {
      width: 78%;
    }
  }

  .pictures {
    max-width: 100%;

    position: relative;
    z-index: 3;

    @media (max-width: 767px) {
      padding-bottom: 70px;
      padding-left: 0;
    }

    @media (min-width: 768px) {
    padding: 0;
    }
  }

  .picture {
    max-width: 100%;
    height: 450px;

    @media (min-width: 576px) {
      padding-left: 15px;
    }

    @media (min-width: 768px) {
      height: 750px;
    }

    @media (min-width: 992px) {
      padding-left: 70px;
    }

    img {
      object-fit: cover;
      width: 85%;
      height: 100%;

      @media (max-width: 767px) {
        width: 90%;
      }
    }
  }

  .block-list {
    padding-top: 70px;
  }

  .list {
    padding-top: 30px;
    padding-left: 45px;

    @media (max-width: 991px) {
      padding-left: 0;
    }
  }

  .list__el {
    padding-bottom: 15px;
    position: relative;

    @media (max-width: 991px) {
      padding-left: 20px;
    }
  }
  .list__el:last-child {
    padding-bottom: 0;
  }
  .list__el:before {
    content: '';
    display: block;
    height: 3px;
    width: 20px;
    background: #71a79e;
    position: absolute;
    top: 9px;
    left: -44px;

    @media (max-width: 991px) {
      width: 5px;
      height: 5px;
      border-radius: 5px;
      top: 8px;
      left: 0;
    }
  }

  .remotivation {
    width: 200px;
    background-image: url(../img/chantal-run-small.jpg);
    background-size: cover;
    position: absolute;
    top: 80px;
    right: 0;
  }
}

.remotivation {
  .text {
    font-family: "bookmania",serif;
    font-weight: 700;
    color: #fff;
    font-size: 1.45em;
    padding: 160px 25px 30px;
    width: 200px;
  }
}

/*CONTACT---------------------------------------------------------------------*/

.title-contact {
  font-size: 2.211em;
  margin-bottom: 50px;
  max-width: 330px;
  text-align: center;

  @media (max-width: 500px) {
    font-size: 1.8em;
  }
}

.contact {

  .container {
    background: $bgcolor1;
    padding-bottom: 140px;
    position: relative;

    @media (max-width: 575px) {
      padding-top: 20px;
      padding-bottom: 100px;
    }
  }

  .container:before {
    content: " ";
    position: absolute;
    top: -160px;
    left: 0;
    right: 0;
    width: 100%;
    height: 160px;
    background: $bgcolor1;
    z-index: 2;
  }

  .content {
    padding: 0;

    @media (max-width: 1199px) {
      max-width: 320px;
      margin: 0 auto;
    }
  }
}

.block-contact {
  @media (min-width: 576px) {
    padding-left: 75px;
  }

  .phonenumber1, .phonenumber2 {
    position: relative;

  }

  .phonenumber1 a, .phonenumber2 a {
    color: inherit;
  }

  .phonenumber1:before {
    @media (min-width: 576px) {
      content: "MOBILE";
      color: #71a79e;
      font-family: "Gotham" !important;
      font-size: 0.632em;
      font-weight: 700;
      position: absolute;
      top: 5px;
      left: -67px;
    }
  }

  .phonenumber2:before {
    @media (min-width: 576px) {
      content: "FIXE";
      font-family: "Gotham" !important;
      color: #71a79e;
      font-size: 0.632em;
      font-weight: 700;
      position: absolute;
      top: 5px;
      left: -47px;
    }
  }

  .space {
    padding-bottom: 20px;
  }

  .mail a {
    font-family: "Gotham" !important;
    text-decoration: none;
    color: inherit;
    transition: 0.2s;
  }

  .mail a:hover {
    color: #71a79e;
  }
}
