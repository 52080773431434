.menu {
  background-color: rgba(255, 255, 255, 1);
  position: fixed;
  top: 0; right: 0;
  height: 100%;
  z-index: 4;
  display: block;
  transform: translateX(100%);
  -webkit-transition: all .3s cubic-bezier(.215,.61,.356,1);
  transition: all .3s cubic-bezier(.215,.61,.356,1);

  @media (max-width: 767px) {
    width: 100%;
  }

  .menu-nav a {
    &.active:after {
      content: '';
      display: block;
      height: 3px;
      width: 26px;
      background: #a6a698;
      position: absolute;
      top: 9px;
      right: -45px;
    }
  }

  .menu-nav {
    padding-left: 120px;
    padding-right: 40px;
    padding-top: calc(50vh - 132px);
    text-align: right;

    &.active:after {
      content: '';
      display: block;
      height: 3px;
      width: 26px;
      background: #a6a698;
      position: absolute;
      top: 9px;
      right: -45px;
    }

    @media (max-width: 767px) {
      padding-left: 0;
    }

    .menu-nav__el {
      padding-bottom: 20px;
      position: relative;

      &:hover {
        color: $fontcolor-green;
      }
    }

    .menu-nav__el a {
      font-size: 1.2em;
      text-transform: uppercase;
      color: inherit;
      -webkit-transition: all .3s;
      transition: all .3s;
    }

    .menu-nav__el--active:after {
      content: '';
      display: block;
      height: 3px;
      width: 26px;
      background: #a6a698;
      position: absolute;
      top: 9px;
      right: -45px;
    }
  }
}



.menu-nav__el:hover {
  color: $fontcolor-green;
}

.burger {
  width: 31px;
  height: 16px;
  display: block;
  position: fixed;
  top: 50px;
  right: 35px;
  z-index: 11;
  cursor: pointer;

  @media (max-width: 767px) {
    top: 35px;
    right: 30px;
  }

  &:before {
    content: '';
    display: block;
    height: 3px;
    width: 26px;
    background: #a6a698;
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(0deg);
    transform: translateX(0);
    -webkit-transition: all .3s cubic-bezier(.215,.61,.356,1);
    transition: all .3s cubic-bezier(.215,.61,.356,1);
  }

  &:after {
    content: '';
    display: block;
    height: 3px;
    width: 31px;
    background: #a6a698;
    position: absolute;
    top: 12px;
    left: 0;
    transform: rotate(0deg);
    -webkit-transition: all .3s cubic-bezier(.215,.61,.356,1);
    transition: all .3s cubic-bezier(.215,.61,.356,1);
  }
}

.burger:hover:before {
  transform: translateX(6px);
}

.menu-active {
  .menu {
    transform: translateX(0);
  }
  .burger {
    &:after {
      top: 6px;
      width: 26px;
      transform: rotate(45deg);
    }
    &:before {
      top: 6px;
      transform: rotate(-45deg);
    }
  }
}
