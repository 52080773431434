body {
  font-family: "Gotham";
  font-size: 17px;
  font-weight: 200;
  line-height: 1.211;
  color: $fontcolor;
}

h2 {
  font-family: "bookmania",serif;
  font-weight: 700;
  font-size: 2.053em;
  color: $fontcolor2;

  @media (max-width: 500px) {
    font-size: 1.8em;
  }
}

.intro {
  font-size: 1em;
  margin: 60px auto 0;

  @media (min-width: 767px) {
    margin: 120px auto 0;
  }

  @media (min-width: 800px) {
    padding-left: 15%;
  }

  @media (min-width: 1000px) {
    padding-left: 25%;
  }
}

.bookmania {
  font-family: "bookmania",serif;
  font-weight: 700;
  margin-top: 25px;
}

.bold {
  font-weight: 700;
}
