footer {
  padding-bottom: 120px;

  @media (max-width: 767px) {
    padding-bottom: 100px;
  }

  @media (max-width: 575px) {
    padding-bottom: 0;
  }
}
