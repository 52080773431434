@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/gotham-bold/gotham-bold.eot") format("eot"),
  url("/assets/fonts/gotham-bold/gotham-bold.woff") format("woff"),
  url("/assets/fonts/gotham-bold/gotham-bold.woff2") format("woff2"),
  url("/assets/fonts/gotham-bold/gotham-bold.ttf") format("truetype"),
  url("/assets/fonts/gotham-bold/gotham-bold.svg#Gotham-Bold") format("svg");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/gotham-book/gotham-book.eot") format("eot"),
  url("/assets/fonts/gotham-book/gotham-book.woff") format("woff"),
  url("/assets/fonts/gotham-book/gotham-book.ttf") format("truetype"),
  url("/assets/fonts/gotham-book/gotham-book.svg#gotham-book") format("svg");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/gotham-light/gotham-light.eot") format("eot"),
  url("/assets/fonts/gotham-light/gotham-light.woff") format("woff"),
  url("/assets/fonts/gotham-light/gotham-light.ttf") format("truetype"),
  url("/assets/fonts/gotham-light/gotham-light.svg#Gotham-Light") format("svg");
  font-style: normal;
  font-weight: 200;
}
