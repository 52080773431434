header {
  position: relative;

  @media(max-width: 767px) {
    background: $bgcolor1;
  }

  &:before {
    @media(max-width: 767px) {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 400px;
      background: $bgcolor2;
      z-index: 0;
    }
  }

  &:after {
    @media(max-width: 767px) {
      content: " ";
      position: absolute;
      bottom: -120px;
      left: 0;
      right: 0;
      width: 100%;
      height: 120px;
      background: $bgcolor1;
      z-index: 1;
    }
  }

  h1 {
    .title-small {
      font-size: 1.61em;
      line-height: 1.08;
      text-transform: uppercase;
      display: block;
      color: $fontcolor-green;
      padding-bottom: 25px;

      @media (max-width: 500px) {
        font-size: 1.4em;
        padding-bottom: 15px;
      }

      @media (max-width: 767px) {
        text-align: center;
      }
    }

    .title-big {
      font-family: "bookmania",serif;
      font-weight: 700;
      font-size: 2.676em;
      line-height: 1.07;
      color: $fontcolor2;
      padding-top: 10px;
      display: block;

      @media (max-width: 500px) {
        font-size: 1.8em;
      }

      @media (max-width: 767px) {
        text-align: center;
      }
    }
  }

  .container{
    background: $bgcolor1;
    padding-top: 115px;

    @media (max-width: 767px) {
      padding-top: 10vh;
    }
  }

  .margin {
    @media (max-width: 767px) {
      margin-top: 100px;
    }
  }

  .padding-no {
    @media (min-width: 767px) {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .picture{
    max-width: 100%;
    margin-top: -100px;

    @media (min-width: 768px) {
      margin-top: -140px;
      height: 560px;
    }

    @media (min-width: 1201px) {
      height: 540px;
      margin-top: -100px;
    }

    @media (max-width: 767px) {
      padding-top: 10vh;
      height: 60vh;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;

      @media (max-width: 767px) {
        display: block;
        margin: 0 auto;
      }
    }
  }
}
